import React, { useState } from "react";
import "./Nav.css";
import PanierIcon from "../img/shopping-bag-solid.svg";
import Panier from "./Panier"; // Import du panier

// Interface pour un outil
interface Outil {
    Nom: string;
    Image: string;
    Description: string;
    Panier: boolean;
    Prix: number; // Ajout du prix
  }
  

interface NavProps {
  panier: Outil[];
  togglePanier: (outil: Outil) => void;
  PasserCommande: () => void;
}




const Nav: React.FC<NavProps> = ({ panier, togglePanier, PasserCommande }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  function LanceCommande() {
    setIsSidebarOpen(false);
    PasserCommande()
  }

  return (
    <>
      <div className="Nav">
        <div className="NavContent">
          <div className="celeste">
            <h2>Celeste</h2>
            <h2>Favaro</h2>
          </div>
          <h2>Boutique</h2>
          <div className="OutilBottom">
            <button
              className="OutilBottom"
              onClick={() => setIsSidebarOpen(true)}
            >
              <img style={{ width: "50px" }} src={PanierIcon} alt="panier" />
              <p className="pNum">{panier.length}</p>
            </button>
          </div>
        </div>
      </div>

      {/* Barre latérale */}
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={() => setIsSidebarOpen(false)}>
          ✖
        </button>
        <Panier panier={panier} togglePanier={togglePanier} PasserCommande={LanceCommande} />
      </div>

      {/* Overlay pour fermer le panier en cliquant à l'extérieur */}
      {isSidebarOpen && (
        <div className="overlay" onClick={() => setIsSidebarOpen(false)}></div>
      )}
    </>
  );
};

export default Nav;
