import { useEffect, useState } from "react";
import "./SquarePaymentForm.css"

const appId = "sandbox-sq0idb-TSY64J7s_0dAwGpSkoi20w";
const locationId = "L5WWGCQ9GN8R8";

interface Outil {
  Nom: string;
  Image: string;
  Description: string;
  Panier: boolean;
  Prix: number; // Ajout du prix
}

interface PaymentProps {
  customerId: string;
  orderId: string;
}

const PaymentComponent = ({customerId,orderId}:PaymentProps) => {
  const [payments, setPayments] = useState<any>(null);
  const [card, setCard] = useState<any>(null);
  const [status, setStatus] = useState<"SUCCESS" | "FAILURE" | "">("");
  const [amount, setAmount] = useState<number>(5); // Amount in cents (2 EUR = 200 cents)

  useEffect(() => {
    const loadSquare = async () => {
      if (!window.Square) {
        console.error("Square.js failed to load properly");
        return;
      }
      try {
        const paymentsInstance = window.Square.payments(appId, locationId);
        setPayments(paymentsInstance);

        // Attach card form
        const cardInstance = await paymentsInstance.card();
        const cardContainer = document.getElementById("card-container");
        if (cardContainer) {
          await cardInstance.attach(cardContainer);
        }
        setCard(cardInstance);

      } catch (error) {
        console.error("Error initializing Square payments", error);
      }
    };
    loadSquare();
  }, []);

  const createPayment = async (token: string) => {
    const data = new URLSearchParams();
    data.append("locationId", locationId);
    data.append("sourceId", token);
    data.append("amount", amount.toString());
    data.append("customerId", customerId);
    data.append("orderId", orderId);

    try {
      const response = await fetch("/backend/payment.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: data.toString(),
      });

      if (!response.ok) {
        throw new Error("Payment failed");
      }
      return response.json();
    } catch (error) {
      console.error("Payment creation error: ", error);
      throw error;
    }
  };

  const handlePayment = async (paymentMethod: any) => {
    try {
      const jsonToken = await paymentMethod.tokenize();
      const token = jsonToken.token
      const response = await createPayment(token);
      setStatus("SUCCESS");
    } catch (error) {
      setStatus("FAILURE");
    }
  };

  return (
    <div>
      <form id="payment-form">
        <div id="google-pay-button"></div>
        <div id="card-container"></div>
        <button type="button" onClick={() => card && handlePayment(card)}>
          Pay 5.00 EUR
        </button>
      </form>
      {status && <div className={status === "SUCCESS" ? "success" : "failure"}>{status}</div>}
    </div>
  );
};

export default PaymentComponent;
