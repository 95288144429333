import React, { useState } from "react";
import "./Outil.css";
import PanierIcon from "../img/shopping-bag-solid.svg";
import CorbeilleIcon from "../img/trash-alt.svg";

interface Outil {
  Nom: string;
  Image: string;
  Description: string;
  Panier: boolean;
  Prix: number; // Ajout du prix
}

interface OutilProps {
  outil: Outil;
  estDansPanier: boolean;
  togglePanier: (outil: Outil) => void;
}

function Outil({ outil, estDansPanier, togglePanier }: OutilProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Ouvrir la popup
  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);
  const handleOverlayClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) closePopup();
  };

  return (
    <div className="contenu">
      <div className="grid-item" onClick={openPopup}>
        <div className="image-container">
          <img src={outil.Image} alt={outil.Nom} />
        </div>
        <div className="OutilBottom">
          <h2 className="Outilp">{outil.Prix.toFixed(2)} €</h2>
          <button className="OutilBottom" onClick={() => togglePanier(outil)}>
            <img
              style={{ width: "40px" }}
              src={estDansPanier ? CorbeilleIcon : PanierIcon}
              alt="panier"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Outil;
