import CommandeForm from "./CommandeForm";
import "./CommandeModal.css"

interface Outil {
    Nom: string;
    Image: string;
    Description: string;
    Panier: boolean;
    Prix: number; // Ajout du prix
  }

interface CommandeModalProps {
    isOpen: boolean;
    onClose: () => void;
    saveUserId: (customerId:string) => void;
    customerId: string;
    saveOrderId: (orderId:string) => void;
    orderId: string;
    togglePanier: (outil: Outil) => void;
    panier: Outil[];
}

export default function CommandeModal({ isOpen, onClose, saveUserId, customerId, saveOrderId, orderId, togglePanier, panier }: CommandeModalProps) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>✖</button>
                <CommandeForm saveUserId={(newId) => saveUserId(newId)} customerId={customerId} saveOrderId={(newId) => saveOrderId(newId)} orderId={orderId} panier={panier} togglePanier={togglePanier} />
            </div>
        </div>
    );
}
