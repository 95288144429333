import { useState } from "react";
import "./CommandeForm.css"
import Combobox from "react-widgets/Combobox";
import SquarePaymentForm from "./SquarePaymentForm"

interface Outil {
    Nom: string;
    Image: string;
    Description: string;
    Panier: boolean;
    Prix: number; // Ajout du prix
}

interface CommandeProps {
    saveUserId: (customerId:string) => void;
    customerId: string;
    saveOrderId: (orderId:string) => void;
    orderId: string;
    togglePanier: (outil: Outil) => void;
    panier: Outil[];
}

const locationId = "L5WWGCQ9GN8R8";

export default function CommandeForm({ saveUserId, customerId, saveOrderId, orderId, togglePanier, panier }: CommandeProps) {
    const [step, setStep] = useState(1);
    const totalSteps = 5; // Nombre total d'étapes
    const [suggestions, setSuggestions] = useState<{ label: string, name:string, city: string, postcode: string }[]>([]);
    const [status, setStatus] = useState<"SUCCESS" | "FAILURE" | "">("");

    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        adresse: "",
        complement: "",
        codePostal: "",
        ville: "",
        pays: "France",
        produits: "",
        quantite: "",
        modePaiement: "",
        numeroCarte: "",
        dateExpiration: "",
        cvv: ""
    });

    // Fonction pour gérer les changements dans les champs du formulaire
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));

        // 🔍 Si on tape dans l'adresse, chercher des suggestions
        if (name === "adresse" && value.length > 3) {
            fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}`)
                .then(res => res.json())
                .then(data => {
                    if (data.features) {
                        setSuggestions(data.features.map((item: any) => ({
                            label: item.properties.label,
                            name : item.properties.name,
                            city: item.properties.city,
                            postcode: item.properties.postcode,
                        })));
                    }
                })
                .catch(error => console.error("Erreur API Adresse :", error));
        } else {
            setSuggestions([]);
        }
    };

    const handleSelectAddress = (suggestion: { label: string, name:string, city: string, postcode: string }) => {
        setFormData(prev => ({
            ...prev,
            adresse: suggestion.name,
            ville: suggestion.city,
            codePostal: suggestion.postcode,
        }));
        setSuggestions([]); // Fermer les suggestions
    };


    const saveCustomerId = async (response: Response) => {
        if (!response.ok) {
            saveUserId(""); // Si la réponse n'est pas OK, on sauvegarde une chaîne vide
        } else {
            try {
                const data = await response.json(); // ✅ Attendre la résolution du JSON
                if (data && data.customer && data.customer.id) {
                    console.log(data.customer.id)
                    saveUserId(data.customer.id); // ✅ Accéder correctement à customer.id
                } else {
                    saveUserId(""); // En cas de données incorrectes
                }
            } catch (error) {
                console.error("Erreur lors de la lecture du JSON :", error);
                saveUserId(""); // Gestion d'erreur si JSON mal formé
            }
        }
    };

    const saveCommandeId = async (response: Response) => {
        if (!response.ok) {
            saveOrderId(""); // Si la réponse n'est pas OK, on sauvegarde une chaîne vide
        } else {
            try {
                const data = await response.json(); // ✅ Attendre la résolution du JSON
                if (data && data.order && data.order.id) {
                    console.log(data.order.id)
                    saveOrderId(data.order.id); // ✅ Accéder correctement à customer.id
                } else {
                    saveOrderId(""); // En cas de données incorrectes
                }
            } catch (error) {
                console.error("Erreur lors de la lecture du JSON :", error);
                saveOrderId(""); // Gestion d'erreur si JSON mal formé
            }
        }
    };

    const createUser = async () => {
        const data = new URLSearchParams();
        data.append("nom", formData.nom);
        data.append("mail", formData.email);
        data.append("prenom", formData.prenom);
        data.append("telephone", formData.telephone);
        data.append("adresse", formData.adresse);
        data.append("complement_adresse", formData.complement);
        data.append("code_postal", formData.codePostal);
        data.append("ville", formData.ville);
    
        try {
          const response = await fetch("/backend/user.php", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: data.toString(),
          });
    
          if (!response.ok) {
            throw new Error("Customer creation failed");
          }

          saveCustomerId(response)
        } catch (error) {
          console.error("Customer creation error: ", error);
          throw error;
        }
      };

    // Fonction pour naviguer entre les étapes
    const nextStep = () => {
        
        // Générer un identifiant customer 
        if (step===2){ createUser() }

        setStep(step + 1);
    }
    const prevStep = () => setStep(step - 1);


  const createOrder = async () => {
    const data = new URLSearchParams();
    data.append("locationId", locationId);
    data.append("customerId", customerId);

    panier.forEach((outil, index) => {
      data.append(`line_items[${index}][name]`, outil.Nom);
      data.append(`line_items[${index}][quantity]`, "1"); // Ajustable
      data.append(`line_items[${index}][base_price_money][amount]`, outil.Prix.toString());
      data.append(`line_items[${index}][base_price_money][currency]`, "EUR");
    });

    try {
      const response = await fetch("/backend/order.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: data.toString(),
      });

      if (!response.ok) {
        throw new Error("Payment failed");
      }
      
      saveCommandeId(response)

    } catch (error) {
      console.error("Payment creation error: ", error);
      throw error;
    }
  };

  const handleOrder = async () => {
    try {
      const response = await createOrder();
      setStatus("SUCCESS");
      nextStep()
    } catch (error) {
      setStatus("FAILURE");
    }
  };

    return (
        <div className="commande-container" onClick={() => setSuggestions([])}>

            {/* ✅ Barre de progression */}
            <div className="progress-container">
                <div className="progress-bar" style={{ width: `${(step / totalSteps) * 100}%` }}></div>
            </div>
            {/* ✅ Affichage des étapes */}
            {/* <p>Étape {step} sur {totalSteps}</p> */}

            {step === 1 && (
                <div className="form-step">
                    <div className="form-input">
                        <h2>Informations personnelles</h2>

                        <input className="inputCommande" type="text" name="nom" placeholder="Nom" value={formData.nom} onChange={handleChange} autoComplete="name" required />
                        <input className="inputCommande" type="text" name="prenom" placeholder="Prenom" value={formData.prenom} onChange={handleChange} autoComplete="surname" required />
                        <input className="inputCommande" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} autoComplete="email" required />
                        <input className="inputCommande" type="tel" name="telephone" placeholder="Téléphone" value={formData.telephone} onChange={handleChange} autoComplete="tel" required />
                    </div>

                    <div className="form-button">
                        <button className="buttonCommande" onClick={nextStep}>Suivant</button>
                    </div>
                </div>

            )}

            {step === 2 && (
                <div className="form-step">
                    <div className="form-input">
                        <h2>Adresse de livraison</h2>
                        <div className="adresse-container">
                            <input
                                className="inputCommande"
                                type="text"
                                name="adresse"
                                placeholder="Adresse"
                                value={formData.adresse}
                                onChange={handleChange}
                                autoComplete="off"
                                required
                            />
                            
                            {/* 🔽 Liste des suggestions, affichée sous l'input */}
                            {suggestions.length > 0 && (
                                <ul className="suggestions">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSelectAddress(suggestion)}>
                                            {suggestion.label}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <input className="inputCommande" type="text" name="complement" placeholder="Complément d'adresse (optionnel)" value={formData.complement} onChange={handleChange} />
                        <input className="inputCommande" type="text" name="codePostal" placeholder="Code Postal" value={formData.codePostal} onChange={handleChange} autoComplete="postal-code" required />
                        <input className="inputCommande" type="text" name="ville" placeholder="Ville" value={formData.ville} onChange={handleChange} autoComplete="address-level2" required />

                        <select className="inputCommande"  name="pays" value={formData.pays} disabled >
                            <option value="France">France</option>
                        </select>
                    </div>
                    <div className="form-button">           
                        <button className="buttonCommande" onClick={prevStep}>Précédent</button>
                        <button className="buttonCommande" onClick={nextStep}>Suivant</button>
                    </div>
                </div>

            )}


            {step === 3 && (
                <div className="form-step">
                    <div className="form-input">
                        <h2>Récapitulatif de la commande</h2>
                        <input className="inputCommande" type="text" name="produits" placeholder="Produits commandés" value={formData.produits} onChange={handleChange} required />
                        <input className="inputCommande" type="number" name="quantite" placeholder="Quantité" value={formData.quantite} onChange={handleChange} required />
                    </div>
                    <div className="form-button">
                        <button className="buttonCommande" onClick={prevStep}>Précédent</button>
                        <button 
                        type="button" 
                        className="buttonCommande" 
                        onClick={() => handleOrder() }
                        >Suivant</button>
                    </div>
                </div>
            )}

            {step === 4 && (
                <div className="form-step">
                    <div className="form-input">
                        <h2>Informations de paiement</h2>
                        <SquarePaymentForm orderId={orderId} customerId={customerId}></SquarePaymentForm>
                    </div>
                    <div className="form-button">
                        <button className="buttonCommande" onClick={prevStep}>Précédent</button>
                    </div>
                </div>
            )}
        </div>
    );
}
