import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";  // Importer les éléments nécessaires
import "react-toastify/dist/ReactToastify.css"; // Importer le CSS de react-toastify
import Nav from "./components/Nav";
import Outils from "./components/Outils";
import "./App.css";
import Panier from "./components/Panier";
import SquareOrderForm from "./components/SquareOrderForm";
import CommandeModal from "./components/CommandeModal";

interface Outil {
  Nom: string;
  Image: string;
  Description: string;
  Panier: boolean;
  Prix: number; // Ajout du prix
}

const App: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [panier, setPanier] = useState<Outil[]>(() => {
    try {
      const panierStocké = localStorage.getItem("panier");
      return panierStocké ? JSON.parse(panierStocké) : [];
    } catch (error) {
      console.error("Erreur lors du chargement du panier :", error);
      return [];
    }
  });
  const [customerId, setCustomerId] = useState<string>(() => {
    try {
      const customerId = localStorage.getItem("customerId");
      return customerId ? JSON.parse(customerId) : "";
    } catch (error) {
      console.error("Erreur lors du chargement du user :", error);
      return "";
    }
  })
  const [orderId, setOrderId] = useState<string>(() => {
    try {
      const orderId = localStorage.getItem("orderId");
      return orderId ? JSON.parse(orderId) : "";
    } catch (error) {
      console.error("Erreur lors du chargement du order :", error);
      return "";
    }
  })


  useEffect(() => {
    localStorage.setItem("panier", JSON.stringify(panier));
  }, [panier]);

  useEffect(() => {
    localStorage.setItem("customerId", customerId);
  }, [customerId]);

  useEffect(() => {
    localStorage.setItem("orderId", orderId);
  }, [orderId]);


  // Fonction pour afficher le toast
  const afficherToast = (nom: string, ajout: boolean) => {
    let message: string

    if(ajout){ message=`${nom} a été ajouté au panier !` } else { message=`${nom} a été retiré du panier` }

    toast.success(message, {
      position: "top-left",
      autoClose: 1000, // Disparait après 3 secondes
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      
    });
  };

  const togglePanier = (outil: Outil) => {
    setPanier((prevPanier) => {
      const existe = prevPanier.some((item) => item.Nom === outil.Nom);
      const newPanier = existe
        ? prevPanier.filter((item) => item.Nom !== outil.Nom)
        : [...prevPanier, { ...outil, Panier: true }];

      localStorage.setItem("panier", JSON.stringify(newPanier));
      if (!existe) {
        afficherToast(outil.Nom,true);  // Affiche le toast lorsque l'outil est ajouté
      } else {
        afficherToast(outil.Nom,false);  // Affiche le toast lorsque l'outil est ajouté
      }
      return newPanier;
    });
  };

  function PasserCommande() {
    setIsModalOpen(true)
  }

  return (
    <div className="App">
      <Nav panier={panier} togglePanier={togglePanier} PasserCommande={() => PasserCommande()} />
      <br />
      <Outils togglePanier={togglePanier} panier={panier}  />
      <br />
      <br />
      <Panier panier={panier} togglePanier={togglePanier} PasserCommande={() => PasserCommande()} />
      <br />
      <br />
      <br />
      <br />
      {/* Ajouter le composant ToastContainer ici pour afficher les toasts */}
      <ToastContainer />
      <CommandeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} saveUserId={(newId) => setCustomerId(newId)} customerId={customerId} saveOrderId={(newId) => setOrderId(newId)} orderId={orderId}  panier={panier} togglePanier={togglePanier} />
    </div>
  );
};

export default App;
