import React from "react";
import "./Panier.css"
import CorbeilleIcon from "../img/trash-alt.svg"; // Import de l'icône

interface Outil {
  Nom: string;
  Image: string;
  Description: string;
  Panier: boolean;
  Prix: number; // Ajout du prix
}

interface PanierProps {
  panier: Outil[];
  togglePanier: (outil: Outil) => void;
  PasserCommande : () => void;
}

const Panier: React.FC<PanierProps> = ({ panier, togglePanier, PasserCommande }) => {

   // Calcul du total du panier
   const total = panier.reduce((acc, item) => acc + item.Prix, 0);

  return (
    <div className="panier">
      <h2>Mon Panier</h2>
      <div className="separateur"></div>
      {panier.length === 0 ? (
        <h3>Panier vide.</h3>
      ) : (
        panier.map((item) => (
          <>
            
            <div className="panier-item">
              <img src={item.Image} alt={item.Nom} className="panier-image" style={{ height: "100px", margin: "20px" }} />
              <h3>{item.Nom}</h3>
              <div className="prix-et-bouton">
                  <h3>{item.Prix.toFixed(2)} €</h3>
                  <button className="supprimer-btn" onClick={() => togglePanier(item)}>
                      <img src={CorbeilleIcon} alt="Supprimer" style={{ width: '40px', height: '40px' }} />
                  </button>
              </div>
          </div>
          <div className="separateur"></div>
                      
          </>
        ))
      )}

      {/* Affichage du total */}
      {panier.length > 0 && (
        <div>
          <h3>Total : {total.toFixed(2)} €</h3>
          <button className="button-commande" onClick={PasserCommande}>Passer commande</button>
        </div>
      )}

    </div>
  );
};

export default Panier;
