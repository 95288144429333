import OutilBloc from "./Outil";
import "./Outils.css";
import collection from "../img/COUVERTURE.jpg";
import angoisse from "../img/ANGOISSE.jpeg";
import temporalite from "../img/PARCOURS.jpeg";
import cigarette from "../img/CIGARETTE.jpeg";
import diagnostic from "../img/DIAGNOSTIC.jpeg";
import plafonnement from "../img/PLAFONNEMENT.jpeg";
import psychiatrie from "../img/PSYCHIATRIE.jpeg";
import psychopathe from "../img/PSYCHOPATHE.jpeg";
import traitement from "../img/TRAITEMENT.jpeg";

interface Outil {
  Nom: string;
  Image: string;
  Description: string;
  Panier: boolean;
  Prix: number; // Ajout du prix
}


interface OutilsProps {
  togglePanier: (outil: Outil) => void;
  panier: Outil[];
}

const outilListe: Outil[] = [
  { Nom: "La collection", Image: collection, Description: "Description...", Panier: false, Prix: 40 },
  { Nom: "L'angoisse", Image: angoisse, Description: "Description...", Panier: false, Prix: 5 },
  { Nom: "La temporalité", Image: temporalite, Description: "Description...", Panier: false, Prix: 5  },
  { Nom: "La cigarette", Image: cigarette, Description: "Description...", Panier: false, Prix: 5  },
  { Nom: "Le diagnostic", Image: diagnostic, Description: "Description...", Panier: false, Prix: 5  },
  { Nom: "Le plafonnement", Image: plafonnement, Description: "Description...", Panier: false, Prix: 5  },
  { Nom: "La psychiatrie", Image: psychiatrie, Description: "Description...", Panier: false, Prix: 5  },
  { Nom: "Le psychopathe", Image: psychopathe, Description: "Description...", Panier: false, Prix: 5  },
  { Nom: "Le traitement", Image: traitement, Description: "Description...", Panier: false, Prix: 5  }
];

function Outils({ togglePanier, panier }: OutilsProps) {
  return (
    <div className="grid-container">
      {outilListe.map((outil) => (
        <OutilBloc
          key={outil.Nom}
          outil={outil}
          estDansPanier={panier.some((item) => item.Nom === outil.Nom)}
          togglePanier={togglePanier}
        />
      ))}
    </div>
  );
}

export default Outils;
